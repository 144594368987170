import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfig } from './../app.config';
import { Injectable } from '@angular/core';
import { DataHistorico } from '../models/dataH';

@Injectable({
  providedIn: 'root'
})
export class HistoricoService {
  headers = new HttpHeaders({
    'accept': 'application/json',
    'Content-Type': 'application/json',
  });

  constructor(
    private config: AppConfig,
    private httpClient: HttpClient
  ) { }

  async listado(){
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    });
    const url = await this.config.get("endpoint");
    const data: DataHistorico = {
      "idPantallaMedic": "35a5967f-7af0-11ed-bddc-002248096076",
      "fecha": '2022-12-16T19:03:47.423Z'
    }
    console.log(data);
    try {
      return await this.httpClient.post('https://apimedic.totalpack.cl/Callmedic/v1/historico', data, {headers: headers}).toPromise();
    } catch (error) {
      console.log(error);
      let resultado = {
        'info' : {
          'status'  : false,
          'data'    : 'error al ejecutar petición',
          'coderr' : error.status
        }
      };
   //   return resultado;
    }

  }

  async listados() {
    //const config = await this.config.getConfig();
    const data = {
      "idPantallaMedic": "35a5967f-7af0-11ed-bddc-002248096076",
      "fecha": "2022-12-15"
    }

    try {
      return await this.httpClient
        .post('https://apimedic.totalpack.cl/Callmedic/v1/historico', data, { headers: this.headers })
        .toPromise();
    } catch (error: any) {
      console.log(error);
      return {
        status: false,
        code: error.error.status,
        message: error.error.title,
      };
    }
  }


}
